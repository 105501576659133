import React from "react"
import PropTypes from "prop-types"
import { FaTwitter, FaHeart, FaCode } from 'react-icons/fa';
import { OutboundLink } from "gatsby-plugin-google-gtag"

import "./site.scss"
import Header from "./header"

const Layout = ({ children, title }) => (
    <>
      <div id="stars" className="stars"></div>
      <div id="stars2" className="stars"></div>
      <div id="stars3" className="stars"></div>

      <div className="page-wrapper">
        <Header siteTitle={title} />
        <main>{children}</main>

        <footer className="my-6">
          <div className="content has-text-centered">
            <p className="is-size-6 has-text-white is-font-pixel">
              Collection by <OutboundLink href="https://www.darkport.co.uk" target="_blank">Paul Price</OutboundLink>
              <OutboundLink href="https://www.twitter.com/darkp0rt" target="_blank" rel="noreferrer" className="ml-3"><FaTwitter /></OutboundLink>
            </p>

            <p className="is-size-7 has-text-white is-font-pixel">
              Made with <FaHeart style={{ color: "red" }} /> and <FaCode />
            </p>
          </div>
        </footer>
      </div>
    </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
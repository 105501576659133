import PropTypes from "prop-types"
import React from "react"
import Link from 'gatsby-link'
import { FaTwitter, FaDiscord } from 'react-icons/fa';
import { OutboundLink } from "gatsby-plugin-google-gtag"

import icon from "../images/icon.png"

const Header = ({ siteTitle }) => (
  <header className="mt-5">
    <div className="is-flex is-align-items-center is-justify-content-center">
      <OutboundLink href="https://www.twitter.com/mypixelplanet" target="_blank" rel="noreferrer" title="Follow us on Twitter" className="mr-6"><FaTwitter className="is-size-3" style={{ color: "rgb(18, 255, 176)" }} /></OutboundLink>
      <Link to="/" title="Go home"><img src={icon} className="image is-64x64 has-text-centered mb-5" style={{ margin: "0 auto" }} /></Link>
      <OutboundLink href="https://discord.gg/RaDUMkkYbz" target="_blank" rel="noreferrer" title="Join the Discord chat" className="ml-6"><FaDiscord className="is-size-3" style={{ color: "rgb(18, 255, 176)" }} /></OutboundLink>
    </div>

    <h1 className="title has-text-centered is-font-pixel is-size-0 is-size-2-mobile">
      <Link to="/" style={{ color: `white`, textDecoration: `none` }}>
        {siteTitle}
      </Link>
    </h1>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header